<template>
  <div class="pay-success">
    <div class="wrap">
      <!-- <van-icon class="icon" name="success" /> -->
      <van-icon class="icon" name="passed" />
      <span class="txt">支付成功</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "paySuccess",
};
</script>

<style lang="scss" scoped>
.pay-success {
  .wrap {
    height: 100px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icon {
      color: #67c23a;
    }

    .txt {
      font-size: 16px;
      color: #5e6d82;
      margin-top: 10px;
    }
  }
}
</style>